@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-input-range/lib/css/index.css';

/* @import 'fonts.css'; */

body {
  @apply text-gray-800;

  &.whats-next {
    @apply bg-none bg-brand-primary-1-default;

    @screen sm {
      @apply bg-none;
    }
  }
}


[type='checkbox'],
[type='radio'] {
  @apply cursor-pointer text-brand-primary-4-darker;
}

.layout {
  @apply flex flex-col mx-auto;
}

/* .layout.quiz { @apply max-w-4xl; }
.layout.order, .layout.results { @apply max-w-6xl; } */
.mkp .layout {
  max-width: 100%;
}

.layout-inner-1 {
  @apply flex flex-col items-center w-full flex-1 justify-center md:justify-start;
}

.lab-results,
.thanks,
.intake-call,
.intake-survey,
.complete,
.thanks,
.address {
  .layout-inner-1 {
    @apply justify-start pt-0;
  }
}

.intake-survey {
  iframe {
    height: 65vh !important;
  }

  .tf-v1-widget-fullscreen iframe {
    height: 100% !important;
  }
}

.layout-inner-2 {
  @apply flex sm:items-stretch w-full justify-center flex-1;
}

.bg-white-lines {
  background-image: url('/lines-white.svg');
  background-position: center right;
  background-repeat: repeat-y;
  background-size: cover;
}

.select-option-item {
  @apply bg-white border-3 border-gray-200 cursor-pointer flex flex-row font-medium items-center justify-start leading-6 p-5 relative rounded-xl text-left text-lg text-secondary1 select-none w-full focus:ring-0;

  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

  -webkit-tap-highlight-color: transparent;

  @screen sm {
    @apply max-w-sm;
  }

  &.selected {
    @apply border-secondary1 shadow-lg;
  }

  &.dense {
    @apply p-3.5 text-sm;
  }

  &:focus,
  &:disabled,
  &:disabled:hover,
  &.disabled {
    @apply shadow-none opacity-50 cursor-not-allowed;
  }

  &:hover:not(.selected, .disabled) {
    @apply border-gray-400;
  }
}


.action-button {
  @apply bg-primary1 cursor-pointer font-semibold leading-7 outline-0 pt-4 pb-3.5 relative rounded-full text-xl tracking-semi-normal w-full flex gap-2 items-center justify-center;

  -webkit-tap-highlight-color: transparent;

  &:hover {
    @apply bg-brand-primary-1-darker;
  }

  &:active {
    @apply relative shadow-none bg-brand-primary-1-default;
    top: 1px;
  }

  &.medium {
    @apply p-2 px-4 text-base;
  }

  &.large {
    @apply p-3 px-6 text-lg;
  }

  &.xl {
    @apply p-4 px-6 text-lg font-bold;
  }

  &.green {
    @apply bg-green-600;
  }

  &.double {
    @apply rounded-lg whitespace-normal;
  }

  &.brand-button {
    @apply bg-brand-primary-1-default text-black;

    &:hover {
      @apply bg-brand-primary-1-lighter;
    }
  }

  &.dark-button {
    @apply bg-brand-dark text-white;

    svg {
      @apply text-white;
    }

    &:hover {
      @apply bg-black/80;
    }
  }

  &.disabled,
  &.dark-button.disabled {
    @apply cursor-not-allowed bg-brand-primary-1-lighter shadow-none text-gray-800/50;

    svg {
      @apply opacity-50;
    }

    &:focus {
      outline: none;
    }
  }

  &.submit-button.disabled {
    @apply bg-brand-primary-4-default/40 text-white;
  }
}

.quiz-heading {
  @apply text-3xl mb-2 font-serif text-center text-secondary1;

  @screen sm {
    @apply text-4xl text-center;
  }

  &.center {
    @apply text-center;
  }
}

.quiz-heading-lg {
  @apply font-serif text-lg text-center text-secondary1 opacity-80;

  &.left {
    @apply text-left;
  }
}

.quiz-heading-2xl {
  @apply font-serif text-2xl leading-7 tracking-tightest text-center text-secondary1;

  &.left {
    @apply text-left;
  }
}

.quiz-heading-3xl {
  @apply font-serif text-2xl leading-8 tracking-tightest text-center text-secondary1;

  @screen sm {
    @apply text-3xl leading-10 tracking-tightest;
  }

  &.error {
    @apply text-brand-danger;
  }
}

.quiz-paragraph {
  @apply text-base text-secondary1 text-center opacity-60 tracking-wider leading-6;

  &.center {
    @apply text-center;
  }

  &.left {
    @apply text-left;
  }

  &-xs {
    @apply text-sm font-medium text-secondary1 text-left opacity-60 tracking-wider leading-6;

    &.left {
      @apply text-left;
    }
  }

  @screen sm {
    @apply text-center max-w-xl mx-auto;

    &.right {
      @apply text-right;
    }

    &-xs {
      @apply mx-auto text-center;
    }
  }
}

.quiz-paragraph-sm {
  @apply text-sm font-medium text-secondary1 text-left opacity-50 tracking-wider leading-6;

  &.center {
    @apply text-center;
  }

  @screen sm {
    @apply text-center max-w-xl mx-auto;
  }
}

.quiz-paragraph-lg {
  @apply text-lg leading-8 font-normal text-secondary1 text-left;

  &.center {
    @apply text-center;
  }

  p {
    @apply mb-6;
  }
}

.quiz-body {
  @apply font-serif text-lg text-secondary1 max-w-xl mx-auto leading-5 tracking-tightest opacity-60;
}

input.quiz-input {
  @apply bg-transparent border-b-2 border-secondary2/25 border-dashed border-t-0 border-x-0 p-0 w-full text-xl text-secondary1 focus:border-secondary1 focus:ring-0;

  &.no-border {
    @apply border-0;
  }

  &[type=number] {
    overflow: hidden;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.invalid {
    @apply text-brand-danger;
  }

  &::placeholder {
    @apply text-secondary2/30;
  }

  &.checkout {
    @apply bg-secondary1/5 border-0 p-1;

    &::placeholder {
      @apply text-secondary2/20 font-normal text-lg;
    }
  }
}

.card-element-container {
  height: 36px;
  margin-top: 2px;
  padding-top: 3px;
}


.quiz-text-input-container {
  @apply flex text-2xl w-1/2 justify-between;

  &.full-w {
    @apply w-full;
  }

  .quiz-input {
    @apply text-heading font-sans;

    @screen sm {
      @apply text-heading-xl;
    }
  }

  span {
    @apply text-heading font-serif;

    @screen sm {
      @apply text-heading-xl;
    }
  }
}

.quiz-step {
  @apply max-w-2xl flex-1 flex flex-col w-full md:w-auto;

  .quiz-header {
    @apply mb-10;

    @screen sm {
      &:not(.with-hint) {
        @apply mb-16;
      }
    }
  }

  .quiz-content {
    @apply pb-20 flex-1;

    @screen sm {
      @apply pb-0;
    }
  }

  &.content {
    p {
      @apply text-lg;
    }
  }

  &.name {
    input {
      @apply mx-auto text-xl;
      max-width: 250px;
    }
  }
}

.callout-text {
  @apply font-normal;

  strong {
    @apply font-semibold italic bg-brand-primary-1-default/30 px-1 rounded-sm;
  }

  span {
    @apply font-medium;
  }

  em {
    @apply font-medium;
  }

  ul {
    @apply list-disc mr-auto;
    padding-left: revert;
  }

  hr {
    @apply my-4;
  }
}

.quiz-step {
  @apply pb-10;

  @screen sm {
    @apply pb-0;
  }
}

.mc-prompt {
  @apply italic text-2xl;
}

.mc-callout-1 {
  @apply font-semibold italic;
}

.mc-callout-2 {
  @apply italic;
}

.next-button {
  @apply block w-full max-w-lg;

  &.disabled {
    @apply text-secondary2 bg-primary1 opacity-50;
  }

  @screen sm {
    @apply inline w-auto px-10;
    min-width: 200px;
  }
}

.action-container {
  @apply w-full fixed inset-x-0 bottom-[20px] px-6;

  @screen sm {
    @apply relative mt-6 py-6 max-w-md mx-auto;
  }

  @screen md {
    @apply pt-20;
  }
}

.boxy {
  @apply flex-1 border border-gray-900/20 bg-white p-6 m-3 flex flex-col items-center justify-center;
}

.bmi-indicator {
  @apply absolute w-4 h-14 bg-gray-300 rounded border-2 border-gray-800 -top-2 shadow-lg;

  &.bmi-0 {
    @apply left-2;
  }

  &.bmi-1 {
    @apply left-6;
  }

  &.bmi-2 {
    @apply left-12;
  }

  &.bmi-3 {
    @apply left-28;
  }

  &.bmi-4 {
    @apply right-12;
  }

  &.bmi-5 {
    @apply right-8;
  }

  &.bmi-6 {
    @apply right-4;
  }

  &.bmi-7 {
    @apply right-1;
  }
}

.question-block {
  @apply my-4 pb-4 w-full cursor-pointer border-b border-brand-primary-4-darker/40;

  .question {
    @apply font-medium pr-6;
    background: url('/icons/cheveron-down.svg') center right no-repeat;
    background-size: 25px;

    @screen sm {
      @apply text-lg;
    }
  }

  .answer {
    @apply hidden pt-2;
  }

  &.selected {
    .question {
      background: url('/icons/cheveron-up.svg') center right no-repeat;
      background-size: 25px;
    }

    .answer {
      @apply block;
    }
  }
}

.feature-list {
  font-size: 100%;

  li {
    @apply my-2 text-left;

    svg {
      @apply relative;
      top: 2px;
    }
  }
}

.green-check {
  li {
    @apply pl-6 mb-1;
    background-image: url('/icons/blue-check.svg');
    background-repeat: no-repeat;
    background-position: 0 1px;
    background-size: 20px;
  }
}

.blue-check {
  li {
    @apply relative pl-7 my-2;

    svg {
      @apply absolute top-0 left-0 text-brand-primary-4-lighter fill-current w-6 h-6;
    }
  }
}

.iti {
  @apply w-full;
}

/* .results-features {
  li:last-child {
    @apply font-semibold italic;
  }
} */

.progress-bar {
  @apply left-0 right-0 bg-primary2 absolute z-20;
  @apply w-screen;
  height: 3px;

  @screen sm {
    @apply w-full;
  }

  div {
    @apply bg-primary1 relative rounded-full;
    transition: width 0.5s;
    height: 3px;
  }
}

.terms a {
  @apply underline;
}

.hiw-section {
  @apply bg-brand-primary-1-default/70;
  background-image: url('/lines-white.svg');
  background-position: center right;
  background-repeat: repeat-y;
  background-size: cover;
}

.hiw-item {
  @apply flex flex-row items-center border-l border-brand-primary-4-lighter/70;

  .hiw-wrapper {
    @apply relative ml-6 my-3 p-3 bg-white;

    @screen md {
      @apply mx-6;
    }
  }

  p {
    &::before {
      @apply absolute top-4 w-5 h-5 rounded-full bg-brand-primary-4-default;
      left: -35px;
      content: ' ';
    }

    strong {
      @apply block;

      @screen md {
        @apply inline;
      }
    }
  }

  .separator {
    @apply hidden;

    @screen md {
      @apply inline;
    }
  }
}

.eb-wrapper {
  @apply overflow-hidden;
}

.eb-rail {
  @apply flex flex-row;
  width: 300%;
}

.terms-label {
  @apply mt-4 mb-2 text-xs;

  a {
    @apply border-b border-gray-400 font-medium;
  }
}

.med-section {
  background-image: url('/triangle-bg.svg');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}

.modal {
  @apply bg-black/70 p-2 md:p-0 fixed top-0 z-40 left-0 h-full w-full flex-col items-center justify-center;

  .modal-wrapper {
    @apply max-w-lg md:shadow-lg bg-white w-full overflow-y-auto;
  }

  .modal-title {
    @apply p-3 bg-brand-primary-2-default w-full relative flex justify-between items-center;

    h4 {
      @apply leading-none text-2xl tracking-wide;
    }
  }

  .modal-content {
    @apply relative max-w-2xl w-full z-50;
  }

  .modal-overlay {
    @apply w-full h-full fixed top-0 left-0 z-20;
  }


  &.modal-save-up-to {
    @apply text-center;

    .modal-wrapper {
      @apply max-w-md py-8 rounded-xl;
    }

    .modal-title {
      @apply hidden;
    }

    .modal-content {
      @apply bg-white;

      h2 {
        @apply text-3xl font-bold mb-8;
      }
    }
  }
}

.modal-promo {
  @apply text-center;

  .modal-title {
    @apply bg-brand-primary-1-default;

    h4 {
      @apply text-xl font-semibold;
    }
  }

  .modal-content {
    @apply bg-brand-primary-1-default;
  }
}

.med-benefits {
  @apply text-sm text-center grid grid-cols-3 grid-rows-1 my-4 gap-3;

  >div {
    @apply border-brand-primary-3-darker/70 border-2 pb-2 px-1 md:p-1 flex flex-col items-center justify-center md:flex-row md:text-left leading-tight;
  }

  svg {
    @apply md:w-20 h-12;
    transform: scale(0.6);

    @screen md {
      transform: scale(0.5);
    }
  }
}

.rx-list {
  @apply grid grid-rows-2 md:grid-rows-1 grid-cols-2 md:grid-cols-4 gap-1 m-auto w-full text-lg md:text-base;

  >div {
    @apply flex flex-row items-center justify-start gap-1;
  }
}

.product-descriptions {
  strong {
    @apply font-medium;
  }
}

.form-control {
  &:disabled {
    @apply opacity-60;
  }
}

.gplaces {
  @apply w-full h-full top-0 bottom-0;
  position: absolute !important;

  input,
  input:focus {
    outline: 2px solid transparent !important;
    --tw-ring-color: transparent !important;
  }
}

.meds-we-prescribe {
  @apply p-5 bg-orange-50;

  .meds-body {
    @apply text-sm;
  }

  @screen md {
    .meds-heading {
      @apply text-lg;
    }

    .meds-body {
      @apply text-base;
    }
  }
}

.layout {

  .progress-bar,
  .back-nav {
    @apply hidden;
  }

  &:not([class*="step-"]) {
    .back-nav {
      @apply hidden;
    }
  }

  &[class*="step-"] {
    .progress-bar {
      @apply block;
    }
  }

  &:not(.processing):not(.final-step-rx):not(.final-step),
  &.final-step,
  &.your-plan,
  &.checkout {
    .back-nav {
      @apply block;
    }
  }

  &.processing,
  &.final-step,
  &.results {
    .progress-bar {
      @apply hidden;
    }
  }

  &.step-1 {
    .back-nav {
      @apply hidden !important;
    }
  }
}

.tf-v1-widget-fullscreen iframe {
  height: 100% !important;
}

.ss-slider {
  @apply mx-auto bg-gray-800/20;
  max-width: 200px;

  @screen md {
    max-width: 275px;
  }
}

.fade-in {
  animation: fadeIn ease 200ms;
  -webkit-animation: fadeIn ease 200ms;
  -moz-animation: fadeIn ease 200ms;
  -o-animation: fadeIn ease 200ms;
  -ms-animation: fadeIn ease 200ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.scale {
  animation: scale ease-in-out 6s infinite;
  -webkit-animation: scale ease-in-out 6s infinite;
  -moz-animation: scale ease-in-out 6s infinite;
  -o-animation: scale ease-in-out 6s infinite;
  -ms-animation: scale ease-in-out 6s infinite;
}

@keyframes scale {

  90%,
  95% {
    transform: scale3d(1.03, 1.03, 1.03);
  }

  0%,
  87%,
  92%,
  97%,
  100% {
    transform: none;
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(187, 208, 255, 0.6);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(187, 208, 255, 0);
  }
}

.shadow-pulse {
  animation: shadow-pulse 1s infinite;
}

/* .banner {
  @apply bg-brand-primary-1-logo text-center text-sm font-semibold py-2 relative cursor-pointer;
} */
.banner {
  @apply relative;
}

.banner-close {
  @apply absolute top-0 bottom-0 right-0 flex items-center justify-center px-2 leading-none font-medium text-3xl z-10 bg-primary1 cursor-pointer;

  @screen sm {
    @apply text-4xl;
  }
}

.spinner {
  margin: 20px auto 10px;
  width: 200px;
  text-align: center;
}

.spinner>div {
  @apply bg-brand-primary-1-logo;
  width: 24px;
  height: 24px;
  margin: 0 5px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.3s infinite ease-in-out both;
  animation: sk-bouncedelay 1.3s infinite ease-in-out both;
}

.spinner .bounce5 {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.spinner .bounce4 {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.spinner .bounce3 {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.spinner .bounce2 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.spinner .bounce1 {
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0)
  }

  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    @apply opacity-50;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  40% {
    @apply opacity-100;
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.list-check {
  @apply text-left pl-6;

  li {
    @apply relative my-2;

    &:before {
      @apply absolute left-0 -ml-6;
      top: 1px;
      content: '✔️';
    }
  }

  &.tight {
    li {
      @apply mt-0 mb-1;
    }
  }
}

.swiper-pagination-bullet {
  @apply bg-secondary1/50;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 8px;
}

.swiper-pagination-bullet:last-child {
  margin-right: 0px;
}

.swiper-pagination-bullet-active {
  @apply bg-black;
}

.quiz-slider-input-range {
  @apply flex flex-wrap min-w-full max-w-2xl px-12 sm:-mx-2 items-start h-[200px] justify-center overflow-hidden;
  @apply w-screen absolute left-0 right-0 pt-4;

  @screen md {
    @apply w-auto relative;
  }

  .input-range {
    @apply pt-20 mt-6;

    @screen md {
      @apply my-8;
    }
  }

  .input-range__label {
    @apply text-xl bottom-0;
  }

  .input-range__track {
    @apply h-2 rounded;
    transition: none;

    .input-range__slider-container {
      transition: none;
    }
  }

  .input-range__track:not(.input-range__track--active) {
    @apply bg-gray-200;
  }

  .input-range__track--active {
    @apply bg-orange-500;
  }

  .input-range__slider {
    @apply w-14 h-14 -ml-8 -mt-8 bg-white border-none shadow-s1;
    background-image: url(../public/slider-selector.svg);
    background-repeat: no-repeat;
    background-position: center center;

    &:active {
      transform: none;
    }
  }

  .input-range__label-container {
    @apply text-heading font-serif text-secondary1;

    @screen sm {
      @apply text-heading-xl;
    }
  }

  .input-range__label--value {
    @apply -top-20 font-medium;

    @screen sm {
      @apply -top-24;
    }
  }

  .input-range__label--max {
    @apply -z-[1];
  }

  .input-range__label--max,
  .input-range__label--min {
    @apply hidden;

    .input-range__label-container {
      @apply text-xl;
    }
  }
}

.quiz-final-step-input {
  @apply bg-transparent border-b-2 border-secondary2/25 border-dashed border-t-0 border-x-0 font-serif form-control max-w-2xl mb-6 mt-5 mx-auto opacity-60 text-center text-heading text-secondary1 tracking-tightest w-full focus:border-secondary1 focus:ring-0;

  &::placeholder {
    @apply text-secondary2/60;
  }

  @screen sm {
    @apply text-heading-xl;
  }
}

.your-plan-container {
  @apply mt-2;

  .cards {
    @apply flex flex-col gap-3;

    .card-item {
      @apply rounded-xl p-5 text-left flex bg-white relative border border-secondary1/20;

      .card-img-container {
        @apply rounded-lg overflow-hidden w-16 h-16 mr-4 bg-secondary1/5;
      }

      .card-heading {
        @apply font-serif text-lg font-medium leading-5 mb-2 text-secondary1;
      }

      .card-body {
        @apply text-sm font-medium leading-6 text-secondary1 text-left opacity-60;
      }
    }
  }

  &.pt1 {
    @apply mt-2;

    .cards {
      @apply flex flex-col gap-3;

      .card-heading,
      .step {
        @apply font-serif text-lg font-medium leading-5 mb-2 text-secondary1;
      }

      .card-item {
        @apply w-full rounded-xl p-5 text-left gap-4 flex bg-white relative border border-secondary1/20;

        .card-step {
          @apply rounded-full text-2xl font-serif text-center h-10 w-10 bg-secondary1/10;
        }

        .card-data {
          @apply flex flex-row;

          .card-img-container {
            @apply rounded-lg overflow-hidden w-16 h-16 mr-4 bg-secondary1/5;
          }

          .card-body {
            @apply text-sm font-medium leading-6 text-secondary1 text-left opacity-60 max-w-[13rem];

            @screen sm {
              @apply max-w-none;
            }
          }
        }
      }
    }

    &.checkout-page {
      .cards {
        .card-item {
          @apply p-0 text-left gap-1 flex bg-white relative border-0;

          .card-img-container {
            @apply w-10 h-10;
          }

          .card-heading {
            @apply font-sans text-sm font-medium mb-1 text-secondary1;
          }

          .card-body {
            @apply text-sm font-medium leading-6 text-secondary1 text-left opacity-60;
          }
        }
      }
    }
  }
}

.good-question-to-ask {
  @apply max-w-lg w-full mx-auto px-4 mb-8 md:mb-12 sm:px-0;

  .good-question-container {
    @apply rounded-xl bg-white py-4 px-6 text-left border border-secondary1/20;

    .good-question {
      @apply py-1.5 cursor-pointer;

      &-header {
        @apply flex items-center justify-between font-serif text-lg font-semibold leading-7 tracking-tightest text-secondary2;

        div {
          @apply bg-primary2 rounded-full p-2;
        }

        @screen sm {
          @apply text-xl;
        }
      }

      &-body {
        @apply hidden transition-transform text-sm font-normal text-secondary2 tracking-wider leading-6 mt-2;

        &.visible {
          @apply block;
        }

        ul {
          @apply list-disc;
          padding: revert;
        }
      }
    }
  }
}

.how-msrd-works {
  .swiper-slide {
    &>div {
      min-height: 274px;

      @screen sm {
        min-height: 348px;
      }
    }
  }

  &-slide {
    @apply rounded-xl bg-white py-9 px-6 text-left border border-secondary1/20;

    .slide-step-indx {
      @apply bg-primary2 text-secondary2 rounded-2xl px-4 font-medium mb-4 w-fit;
    }

    .slide-title {
      @apply font-serif text-xl font-semibold leading-7 tracking-tightest text-secondary2 mb-4;

      @screen sm {
        @apply text-2xl;
      }
    }

    .slide-description {
      @apply font-normal text-secondary2 tracking-wider leading-6;

      @screen sm {
        @apply text-lg;
      }
    }

    .slide-checkmarks {
      @apply my-4 flex flex-col gap-2 text-secondary2;

      div {
        @apply flex gap-6;
      }
    }
  }
}

.state-match {
  @apply h-8 inline-flex items-center my-auto px-2.5 py-0.5 rounded text-base text-secondary1/60 tracking-tightest;

  &.active {
    @apply bg-secondary1/10;
  }
}

.review-content {
  @apply border border-secondary1/20 bg-primary3 rounded-xl py-5 px-6 max-w-md mx-auto text-left;

  .quote {
    @apply font-serif text-base tracking-tightest text-secondary2 mb-8;

    @screen sm {
      @apply text-xl tracking-tightest;
    }
  }

  .user-info {
    @apply flex gap-3.5 items-center;

    img {
      &:first-child {
        @apply rounded-full h-14 w-14;
      }
    }

    span {
      @apply text-secondary2 font-semibold text-sm;
    }
  }
}

.step-ladder {
  @apply flex flex-col gap-7 max-w-lg mx-auto;

  .step {
    @apply flex w-full text-left;

    span {
      @apply flex items-center justify-center w-10 h-10 rounded-full bg-secondary1/20 mr-4 text-xl;
    }

    strong {
      @apply font-semibold tracking-wide;
    }

    > div:nth-child(2) {
      @apply flex-shrink;

      p {
        @apply text-base;
      }
    }
  }
}

.swiper {
  @apply pb-10 !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-primary1;
}

.quiz-values {
  .label {
    @apply font-semibold;
  }
}
